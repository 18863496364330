<template>
  <MglMap
    v-if="centerPoint"
    class="map-height"
    :access-token="mapAccessToken"
    :map-style="mapStyle"
    :center="centerPoint"
    :zoom="zoom"
    :attribution-control="false"
    :logo-position="'bottom-right'"
    :drag-rotate="false"
    :touch-zoom-rotate="false"
    :pitch-with-rotate="false"
    @idle="idleEvent"
    @load="initMap"
    @click="onClickMap"
  >
    <v-overlay
      contained
      :model-value="mapLoading"
      class="align-center justify-center"
      style="z-index: 3"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg
        v-if="mapLoading"
        :height="'600px'"
      />
    </v-overlay>
    <v-overlay
      contained
      :model-value="hasAlert"
      content-class="w-100 h-100"
      scrim="rgba(0,0,0,0)"
      style="z-index: 3"
      :persistent="true"
      :no-click-animation="true"
    >
      <div
        class="d-flex align-center justify-center w-100 h-100"
        style="background-color: rgba(0, 0, 0, 0.6);"
      >
        <div class="overlay-message">
          <p>取得データボリュームが少なく、</p>
          <p>統計上の信頼性の低いデータが含まれています。</p>
          <p>参考値としてご参照ください。</p>
          <v-btn
            variant="text"
            style="text-decoration: underline"
            @click="onClickVOverlay()"
          >
            閉じる
          </v-btn>
        </div>
      </div>
    </v-overlay>
    <MglMarker :coordinates="centerPoint">
      <template #marker>
        <div>
          <img src="@/assets/svg/center-pin.svg">
        </div>
      </template>
    </MglMarker>
    <!-- 商圏マップ -->
    <MglGeojsonLayer
      v-if="bizAreaLayer"
      ref="bizArea"
      :source-id="sourceId"
      :source="bizAreaSource"
      :layer-id="layerId"
      :layer="bizAreaLayer"
      :clear-source="true"
      @click="showOrCloseMapPopup"
      @mousemove="closePopup"
    />
    <!-- グレーアウト用商圏マップ -->
    <MglGeojsonLayer
      v-if="highlightBizAreaLayer"
      ref="highlightBizArea"
      :source-id="sourceId"
      :source="bizAreaSource"
      :layer-id="highlightLayerId"
      :layer="highlightBizAreaLayer"
      :clear-source="true"
    />
    <!-- データ補足範囲 -->
    <MglGeojsonLayer
      v-if="radiusLayer"
      ref="radius"
      :source-id="radiusSource.data.id"
      :source="radiusSource"
      :layer-id="radiusLayer.id"
      :layer="radiusLayer"
      :clear-source="true"
    />
    <!-- 表示目安範囲 -->
    <MglGeojsonLayer
      v-if="viewRadiusLayer"
      ref="viewRadiusRef"
      :source-id="viewRadiusSource.data.id"
      :source="viewRadiusSource"
      :layer-id="viewRadiusLayer.id"
      :layer="viewRadiusLayer"
      :clear-source="true"
    />
    <MglAttributionControl />
    <MglNavigationControl
      :show-compass="false"
      position="top-right"
    />
    <MglScaleControl position="top-left" />
    <MglPopup
      :showed="hidePopup"
      :coordinates="popupCoordinates"
      anchor="bottom-left"
      :close-button="false"
      :close-on-click="false"
    >
      <BizAreaMapPopup
        :title="areaName"
        :text="(Math.round(visitRatio * 1000) / 10).toFixed(1) + '%'"
        @close-popup="closePopup"
      />
    </MglPopup>
    <CustomCircle
      v-model:v-radius="vRadius"
      @set-v-radius="updateViewRadius"
    />
    <button
      class="reset-button"
      @click="$emit('resetActive')"
    >
      <div>エリアの選択を解除</div>
    </button>
  </MglMap>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, watch } from "vue";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup,
} from "@/vendor/vue-mapbox/main";
import CustomCircle from "@/features/ShopAnalytics/components/BizArea/customCircle.vue";
import { MapBoxFeatures } from "@/features/ShopAnalytics/interfaces/response";
import { AreaListItem } from "@/features/ShopAnalytics/interfaces/component";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { MAP_ACCESS_TOKEN, MAP_STYLE } from "@/config";
import BizAreaMapPopup from "./BizAreaMapPopup.vue";

export default defineComponent({
  name: "BizAreaMap",
  components: {
    LoadingImg,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglAttributionControl,
    MglNavigationControl,
    MglScaleControl,
    MglPopup,
    CustomCircle,
    BizAreaMapPopup,
  },
  props: {
    storePoint: {
      type: Object as PropType<{ lat: number; lng: number }> | undefined,
      default: undefined,
    },
    radius: {
      type: Number as PropType<number>, required: true,
    },
    viewRadius: {
      type: Number as PropType<number>, required: true,
    },
    featureCollection: {
      type: Array as PropType<MapBoxFeatures[]>,
      default: () =>{return []},
    },
    maxRatio: {
      type: Number,
      default: 1,
    },
    activeArea: {
      type: Array as PropType<AreaListItem[]>,
      default: () =>{return []},
    },
    mapLoading: {
      type: Boolean,
      default: false,
    },
    hasAlert: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["resetActive", "update:viewRadius", "clickLayer", "updateRoute", "clickApproveAlert"],
  setup(props, { emit }) {
    const sourceId = "bizAreaSourceId";
    const layerId = "bizAreaLayerId";
    const highlightLayerId = "highlightBizAreaLayerId";

    let map: any = undefined;

    const zoom = ref(11.75);
    const radiusSource = ref();
    const radiusLayer = ref();
    const viewRadiusSource = ref();
    const viewRadiusLayer = ref();
    const bizAreaLayer = ref();
    const bizAreaSource = ref();
    const highlightBizAreaLayer = ref();
    const hidePopup = ref(true);
    const popupCoordinates = ref([0, 0]);
    const areaName = ref("");
    const visitRatio = ref(0.0);
    const bizArea = ref();
    const highlightBizArea = ref();
    const radius = ref();
    const viewRadiusRef = ref();

    const centerPoint = computed(() => {
      return props.storePoint
        ? [props.storePoint.lng, props.storePoint.lat]
        : undefined;
    });
    const vRadius = computed({
      get: () => {
        return props.viewRadius;
      },
      set: (newVal: number) => {
        emit("update:viewRadius", newVal);
      }
    });

    const mouseOverLayer = (e: any) => {
      hidePopup.value = true;
      popupCoordinates.value = [
        e.mapboxEvent.features[0].properties.longitude,
        e.mapboxEvent.features[0].properties.latitude,
      ];
      areaName.value = e.mapboxEvent.features[0].properties.areaName;
      visitRatio.value = e.mapboxEvent.features[0].properties.visitRatio;
      hidePopup.value = false;
    };
    const closePopup = () => {
      hidePopup.value = true;
      popupCoordinates.value = [0, 0];
      areaName.value = "";
      visitRatio.value = 0.0;
    };
    const showOrCloseMapPopup = (e: any) => {
      hidePopup.value ? mouseOverLayer(e) : closePopup();
    };
    const onClickMap = (e: any) => {
      const selectedFeatures = map.queryRenderedFeatures(e.mapboxEvent.point);
      if (selectedFeatures.length === 0) return;
      emit("clickLayer", selectedFeatures[0].properties.areaId);
    };
    const initMap = async(event: any) => {
      map = event.map;
      radius.value = props.radius;
    };
    const idleEvent = () => {
      if (!bizAreaSource.value && props.featureCollection.length > 0)
        updateBizArea();
    };
    const updateBizArea = () => {
      if (!map) return;
      resetBizArea();
    };
    const createBizArea = () => {
     bizAreaSource.value = {
        type: "geojson",
        data: {
          id: sourceId,
          type: "FeatureCollection",
          features: props.featureCollection,
        },
      };
      // 通常のレイヤー
      bizAreaLayer.value = {
        id: layerId,
        type: "fill",
        source: bizAreaSource.value,
        layout: {},
        paint: {
          "fill-color": [
            "interpolate",
            ["linear"],
            ["get", "visitRatio"],
            0,
            "#F8DDE0",
            props.maxRatio,
            "#D62F41",
          ],
          "fill-opacity": 0.6,
        },
      };
      // エリア選択時に未選択のエリアをグレーアウトするためのレイヤー
      highlightBizAreaLayer.value = {
        id: highlightLayerId,
        type: "line",
        source: bizAreaSource.value,
        layout: {},
        paint: {
          "line-width": 1,
        },
        filter: ["in", "areaId", ""],
      };
    };
    const resetBizArea = () => {
      if (bizAreaSource.value && bizAreaLayer.value) {
        try {
          map.removeSource(sourceId);
          if (map.getLayer(layerId)) {
            map.removeLayer(layerId);
            map.removeSource(layerId);
          }
        } catch (error) {
          console.error(error);
          throw new Error("Failed to delete layer.");
        }
      }
      if (highlightBizAreaLayer.value) {
        try {
          if (map.getSource(sourceId)) map.removeSource(sourceId);
          if (map.getLayer(highlightLayerId)) {
            map.removeLayer(highlightLayerId);
            map.removeSource(highlightLayerId);
          }
        } catch (error) {
          console.error(error);
          throw new Error("Failed to delete layer.");
        }
      }
      hidePopup.value = true;
      bizAreaSource.value = null;
      bizAreaLayer.value = null;
      highlightBizAreaLayer.value = null;

      if (props.featureCollection.length > 0) {
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 0));
          createBizArea();
          await new Promise((resolve) => setTimeout(resolve, 0));
          // 処理順の関係でデータ補足範囲と距離目安の円がエリアの下に描画されるケースがあるため最上位に呼び出す
          map.moveLayer("radiusLayer");
          map.moveLayer("viewRadiusLayer");
        })();
      }
    };
    const updateRadius = () => {
      if (radius.value) resetRadius();
      else createRadius();
    };
    const createRadius = () => {
      radiusSource.value = {
        type: "geojson",
        data: {
          id: "radiusSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [createCirclePolygon(props.radius)],
              },
            },
          ],
        },
      };
      radiusLayer.value = {
        id: "radiusLayer",
        type: "line",
        source: radiusSource.value,
        layout: {},
        paint: {
          "line-width": 2,
          "line-color": "#666",
          "line-dasharray": [1, 2],
        },
      };
    };
    const resetRadius = () => {
      if (!radiusSource.value) return;
      try {
        map.removeSource(radiusSource.value.data.id);
        if (map.getLayer(radiusLayer.value.id)) {
          map.removeLayer(radiusLayer.value.id);
          map.removeSource(radiusLayer.value.id);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }

      hidePopup.value = true;
      radiusSource.value = null;
      radiusLayer.value = null;

      if (props.radius) {
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 0));
          createRadius();
        })();
      }
    };
    const updateViewRadius = () => {
      if (viewRadiusRef.value) resetViewRadius();
      else createViewRadius();
    };
    const createViewRadius = () => {
      emit("updateRoute");
      viewRadiusSource.value = {
        type: "geojson",
        data: {
          id: "viewRadiusSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [createCirclePolygon(props.viewRadius)],
              },
            },
          ],
        },
      };
      viewRadiusLayer.value = {
        id: "viewRadiusLayer",
        type: "line",
        source: viewRadiusSource.value,
        layout: {},
        paint: {
          "line-width": 4,
          "line-color": "#ff7f78",
        },
      };
    };
    const resetViewRadius = () => {
      try {
        map.removeSource(viewRadiusSource.value.data.id);
        if (map.getLayer(viewRadiusLayer.value.id)) {
          map.removeLayer(viewRadiusLayer.value.id);
          map.removeSource(viewRadiusLayer.value.id);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }

      hidePopup.value = true;
      viewRadiusSource.value = null;
      viewRadiusLayer.value = null;
      if (props.viewRadius) {
        (async () => {
          // NOTE: ここで非同期処理を止めないとGeojsonLayerコンポーネントがエラーを吐くので止めている
          await new Promise((resolve) => setTimeout(resolve, 0));
          createViewRadius();
        })();
      }
    };
    const createCirclePolygon = (rad: number) => {
      const points = 64;
      const coords = {
        latitude: props.storePoint?.lat ?? 0,
        longitude: props.storePoint?.lng ?? 0,
      };

      const radiusPerKm = rad;
      const distanceX =
        radiusPerKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      const distanceY = radiusPerKm / 110.574;

      const ret = [];
      let theta, x, y;
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return ret;
    };
    const onClickVOverlay = () => {
      emit("clickApproveAlert");
    };

    watch(() => props.featureCollection, () => {
      updateBizArea();
      updateRadius();
    });

    watch(() => props.activeArea as Array<AreaListItem>, (newVal: Array<AreaListItem>) => {
      if (newVal.length === 0) {
        map.setFilter(highlightLayerId, ["in", "areaId", ""]);
      } else {
        const activeAreaIds: string[] = newVal.map(
          (activeArea: AreaListItem) => {
            return activeArea.areaId;
          }
        );
        map.setFilter(highlightLayerId, [
          "in",
          "areaId",
          ...activeAreaIds,
        ]);
      }
    });

    return {
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE,
      zoom,
      radiusSource,
      radiusLayer,
      viewRadiusSource,
      viewRadiusLayer,
      sourceId,
      layerId,
      bizAreaLayer,
      bizAreaSource,
      highlightLayerId,
      highlightBizAreaLayer,
      hidePopup,
      popupCoordinates,
      areaName,
      visitRatio,
      centerPoint,
      vRadius,
      mouseOverLayer,
      closePopup,
      showOrCloseMapPopup,
      onClickMap,
      idleEvent,
      initMap,
      updateViewRadius,
      resetViewRadius,
      onClickVOverlay,
      bizArea,
      highlightBizArea,
      viewRadiusRef,
    };
  },
});
</script>

<style scoped>
.map-height {
  height: 600px;
}
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.overlay-message p {
  margin-bottom: 11px;
}
.reset-button {
  position: absolute;
  bottom: 35px;
  right: 10px;
  width: 129px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
}
.reset-button div {
  margin: 0 auto 9px;
  color: #ffffff;
  font: normal normal bold 12px/12px Noto Sans JP;
}
</style>
